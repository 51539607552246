import React from 'react';
import _ from 'lodash';
// import Rule from 'kubra-ux/dist/foundation/Rule';
import { Loading } from './Loading';

const bpu_host = process.env.REACT_APP_BPU_ENDPOINT;

class DataFeedReport extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      bpudata: "",
      error: "",
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });

   // console.log("AREAID:"+this.props.areaId);
    //TODO: replace the url localhost:8080/bpu/datafeed/AREA8
    fetch(bpu_host+'/bpu/datafeed/'+this.props.areaId)
      .then(response => {
          if (response.ok) {
            return response;
          } else {
            var error = new Error('Error ' + response.status + ': ' + response.statusText);
            error.response = response;
            throw error;
          }
        },
        error => {
          // console.log("ERROR:"+JSON.stringify(error));
          var errmess = new Error(error.message);
          throw errmess;
        })
      .then(response => response.json())
      .then(data => {
        // console.log("DATA:"+JSON.stringify(data));
        // dispatch(addBpuData(data));
        this.setState({bpudata:data, isLoading: false});
      })
      .catch(error =>{
          this.setState({ error: error, isLoading: false});

        // dispatch(bpuDataFailed(error.message))
      });
  }

  render(){

    if(this.state.isLoading){
      return (
        <Loading/>
      );
    }
    else if(this.state.error){
      return(
        <p>{this.state.error.message}</p>
      )
    }
    else if (this.state.bpudata['dataFeed']==""){
      return(
        <div></div>
      )
    }
    else{

      return(
        <div className="customfeed_info">
          <h4>Customers Projected to be Restored:</h4>
          <div dangerouslySetInnerHTML={ {__html: _.get(this.state.bpudata, 'dataFeed')} } />
        </div>
      )
    }
  }
}
export default DataFeedReport;
