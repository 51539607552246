import React from 'react';
import _ from 'lodash';
import Rule from 'kubra-ux/dist/foundation/Rule';
import { Loading } from './Loading';
import Moment from 'react-moment';
import moment from 'moment';


const api_host = process.env.REACT_APP_SC5_API_ENDPOINT;
const instance = process.env.REACT_APP_INSTANCE;
const thematicAreas = process.env.REACT_APP_THEMATICAREAS;

class DynamicReport extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      currentState: null,
      error: "",
      isLoading: false,

      configuration: null,
      message: null,
      thematicData: null,

      maskEtr: false,


      timeFormat: 'MMMM D, YYYY h:mm:ss a', // format for all dates

      /*      configuration: null,
            configError: "",
            configIsLoading: false,

            messageBundle: null,
            messageError: "",
            messageIsLoading: false*/
    }

    this.isMaskedETR = this.isMaskedETR.bind(this);
  }

  componentDidMount(){
    this.setState({ isLoading: true });
    fetch(api_host+"/stormcenter/api/v1/stormcenters/"+instance+"/views/"+this.props.viewId +"/currentState")
      .then(response => {
          if (response.ok) {
            return response;
          } else {
            var error = new Error('Error ' + response.status + ': ' + response.statusText);
            error.response = response;
            throw error;
          }
        },
        error => {
          // console.log("ERROR:"+JSON.stringify(error));
          var errmess = new Error(error.message);
          throw errmess;
        })
      .then(response => response.json())
      .then(data => {
        // console.log("DATA:"+JSON.stringify(data));
        // dispatch(addCurrentState(data));
        // console.log(data.stormcenterDeploymentId);
        // console.log(data.messageBundleId);
        // console.log(data.data.interval_generation_data);

        this.setState({currentState:data, isLoading: true});

        /*Promise.all([
          fetch("http://localhost:3001/configuration"),
          fetch("http://localhost:3001/messageBundleId")
        ]).then(allResponses => {
          const response1 = allResponses[0]
          const response2 = allResponses[1]

          console.log(response1);
          console.log(response2);

        })*/

        Promise.all([
          fetch(api_host+"/stormcenter/api/v1/stormcenters/"+instance+"/views/"+ this.props.viewId+ "/configuration/"+ this.state.currentState.stormcenterDeploymentId ).then(value => value.json()),
          fetch(api_host+"/stormcenter/api/v1/stormcenters/"+ instance +"/messages/"+ this.state.currentState.messageBundleId +"/intl/en-US/desktop").then(value => value.json())
        ]).then(([configuration, message]) => {
          /*ReactDOM.render(
            <Test items={items} contactlist={contactlist} itemgroup={itemgroup} />,
            document.getElementById('overview');
        );*/
          // console.log("config: "+ configuration);
          // console.log("message: "+ JSON.stringify(message));
          // console.log(configuration.config.layers.data.interval_generation_data);
          var layer = "thematic-1"; //configuration.config.layers.data.interval_generation_data.filter((layer) => layer.layerName === "thematic-3")[0];
          // console.log("layer:"+layer);
          var source = "";//layer.source[0];
          // console.log("source:"+source);
          var maskEtr = "maskEtr:"; //layer.maskExpiredEtr;
          // var etr_null_key = 'ETR-NULL';
          // var etr_expired_key = 'ETR-EXP';
          // var etr_null_text = message['ETR-NULL'];
          // var etr_expired_text = message['ETR-EXP'];

          // console.log(maskEtr);
          // console.log("etr_null_text: "+etr_null_text);
          // console.log("etr_expired_text: "+ etr_expired_text);

          this.setState({
            configuration: configuration,
            message: message,
            maskEtr: maskEtr,
            isLoading: false
          })

          //TODO: replace the link with source
          return fetch(api_host+'/'+this.state.currentState.data.interval_generation_data+'/public/'+thematicAreas+'/thematic_areas.json')
            .then(response => {
                if (response.ok) {
                  return response;
                } else {
                  var error = new Error('Error ' + response.status + ': ' + response.statusText);
                  error.response = response;
                  throw error;
                }
              },
              error => {
                // console.log("ERROR:"+JSON.stringify(error));
                var errmess = new Error(error.message);
                throw errmess;
              })
            .then(response => response.json())
            .then(data => {
              // console.log("thematic:"+JSON.stringify(data));
              // dispatch(addCurrentState(data));
              this.setState({thematicData:data, isLoading: false});

              // config -> layers -> interval_generation_data (check area_type)
              // -> maskExpiredEtr
              // -> source
              // -> infobox
              // -> config -> customDataMessages (to get masking)

              /*
              * "customDataMessages": {
                "maskText": "mask-text-message-id",
                "percentMask": "mask-percent-text-message-id"
              }
              */

            })
            .catch(error => {
                this.setState({ error: error, isLoading: false });
              }
            );

        }).catch((err) => {
          this.setState({ error: err});
        });
      })
      .catch(error => {
          this.setState({ error: error, isLoading: false });
        }
      );
  }

  isMaskedETR = (etr) =>{

    switch (etr) {
      case this.state.message["ETR-EXP"]:
      case this.state.message["ETR-NULL"]:
        return true;

      default:
        return false;

    }
  };


  render(){
    const { thematicData, isLoading, error } = this.state;

    if (isLoading) {
      return <Loading/>;
    }
    /*if (error) {
      return <p>{error.message}</p>;
    }*/

    if(thematicData){

      var area = thematicData.file_data.filter((area) => area.id === this.props.areaId)[0];

      if(area){
        // console.log(area);

        /*"desc": {
          "name": "ATLANTIC CITY",
            "n_out": 1,
            "cust_s": 2,
            "cust_a": {
            "val": 4,
              "mask": 5
          },
          "percent_cust_a": {
            "val": 100
          },
          "etr": "ETR-EXP",
            "hierarchy": {
            "county": "Atlantic"
          },
          "start_time": "2019-05-24T04:27:06.974Z"*/

        //mask-text-message-id
        var cust_a = area.desc.cust_a.val;
        if(area.desc.cust_a.val < area.desc.cust_a.mask){
          cust_a = this.state.message['mask-text-message-id'].replace('{value}', area.desc.cust_a.mask);
          // console.log(cust_a);
        }

        //mask-percent-text-message if any
        var percent_cust_a = area.desc.percent_cust_a.val;
        if(area.desc.percent_cust_a.val < area.desc.percent_cust_a.mask){
          percent_cust_a = this.state.message['mask-percent-text-message-id'].replace('{value}', area.desc.percent_cust_a.mask);
        }


        var etr = area.desc.etr;
        // console.log(etr);
        if(this.state.maskEtr){
          console.log("TRUE");
          if(this.state.message[etr]){
            etr = this.state.message[etr];
          }
        }else{
          etr = moment(this.state.message[etr] ).format(this.state.timeFormat);
        }

        return(
          <div className="dynamic">
            <div className="outage_info">
            <h4>Current Outage Information</h4>
            <p>
            <span className="content_left">Name of Municipality:</span> <span className="content_right">{area.desc.name}</span>
            </p>
            <Rule/>
            <p>
            <span className="content_left">Customers Affected:</span> <span className="content_right">{cust_a}</span>
            </p>
            <Rule/>
            <p>
            <span className="content_left">Customers Served:</span> <span className="content_right">{_.get(area, 'desc.cust_s')}</span>
            </p>
            <Rule/>
            <p>
              <span className="content_left">Percent Affected:</span> <span className="content_right">{percent_cust_a}%</span>
            </p>
            <Rule/>
            <p>
            <span className="content_left">Number of Outage Orders:</span> <span className="content_right">{_.get(area, 'desc.n_out')}</span>
            </p>
            <Rule/>
            <p>
            <span className="content_left">Earliest Report:</span> <span className="content_right">

                <Moment
                    format={this.state.timeFormat}
                >{_.get(area, 'desc.start_time')}
                </Moment>

                </span>
            </p>
            <Rule/>
            <p>
            <span className="content_left">Estimated Restoration:</span> <span className="content_right">
                {this.isMaskedETR(etr) ? etr : <Moment format={this.state.timeFormat}>{etr}</Moment> }
                </span>
            </p>
            <Rule/>
              <p>
                <i> The Estimated Restoration time above is the most conservative for the outages that are located in this Municipality.</i>
            </p>
            </div>
            {/*{JSON.stringify(currentState)}
            {currentState.map(currentState =>
            <div>
            {currentState}
            </div>
            )}
            {currentState}
            {this.state.currentState === null ? <Loading/> : this.state.currentState}
            {this.state.currentState}
            {
            this.state.error === null ? {this.state.error} : {this.state.currentState}
            }*/}

            {/*{
          this.state.error === null ?
            this.state.currentState === null ?  <p>Loading</p> : <div>{this.state.currentState}</div>
            :<div>{this.state.error}</div>
        }*/}
          </div>
        )

      }else{
        return(
          <div>
            No outage information to display at this time
          </div>
        )
      }
    }else{
      return(
        <div>
          Thematic data not available
        </div>
      )
    }
  }
}
export default DynamicReport;
