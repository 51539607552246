import React from 'react';
import { Loading } from './Loading';


const bpu_host = process.env.REACT_APP_BPU_ENDPOINT;

class StaticReport extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      staticData: null,
      error: "",
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    fetch(bpu_host+'/bpu/staticfeed/areas/'+this.props.areaId)
      .then(response => {
          if (response.ok) {
            return response;
          } else {
            var error = new Error('Error ' + response.status + ': ' + response.statusText);
            error.response = response;
            throw error;
          }
        },
        error => {
          // console.log("ERROR:"+JSON.stringify(error));
          var errmess = new Error(error.message);
          throw errmess;
        })
      .then(response => response.json())
      .then(data => {
        // console.log("DATA:"+JSON.stringify(data));
        // dispatch(addBpuData(data));
        this.setState({staticData:data, isLoading: false});
      })
      .catch(error =>{
        this.setState({ error: error, isLoading: false});

        // dispatch(bpuDataFailed(error.message))
      });
  }

  render(){
    if (this.state.isLoading) {
      return <Loading/>;
    }
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    if(this.state.staticData){
      const electric_infra = this.state.staticData['electricInfo'];
      const addInfo = this.state.staticData['addInfo'];
      console.log(electric_infra);
      console.log(addInfo);
      // const ElectricInfra = Object.entries(electric_infra).map(([key,value])=>{
      //   return (
      //     <p key={key}>{key} : {value.toString()}</p>
      //   );
      // })

      return(
        <div className="static_data">

          <div className="static_info">
            <h4>Additional Information:</h4>
            {addInfo}
          </div>

          <div>
            <h4>Electric Infrastructure:</h4>
            {/*{ElectricInfra}*/}
            <div dangerouslySetInnerHTML={ {__html: electric_infra} } />
          </div>

        </div>
      )
    }else{
      return(
        <div>
        </div>

      )
    }
  }
}
export default StaticReport;
